<template>
  <div>
    <img class="img__body" :src="imgBody" alt="" />
    <div class="container__logo">
      <img class="logo__clear" :src="imgLogo" alt="" />
    </div>

    <div class="container__form__pregunta">
      <form class="form preguntas" @submit.prevent="submitQuestionHandler">
        <vue-progress-bar></vue-progress-bar>
        <div class="timer_container">
          <quiz-count-down v-if="withOutTimer"></quiz-count-down>
        </div>
        <div class="question" v-if="!isDiscQuiz">
          <quiz-statements :statements="this.quiz.quizStatements" />
          <question-title
            :title="quiz.questions[questionIndex].questionStatements"
            :totalQuestions="totalQuestions"
            :actualQuestion="actualQuestion"
          ></question-title>
          <answer-group
            :questionAnswers="quiz.questions[questionIndex].answers"
            :multipleChoice="quiz.questions[questionIndex].multipleChoice"
            :questionIndex="questionIndex"
            v-model="chosenAnswers"
          />
        </div>
        <div class="question" v-if="isDiscQuiz">
          <div>
            <disc-question-group
              :questions="quiz.questions"
              :multipleChoice="quiz.questions[questionIndex].multipleChoice"
              :groupIndex="questionIndex"
              v-model="chosenAnswers"
              @valid="handleValidForm"
            ></disc-question-group>
          </div>
        </div>
        <div class="submit_btn">
          <button
            class="btn__comenzar"
            type="submit"
            :disabled="disableButton || !selectedAnswersValid"
          >
            <template v-if="!loadingButton">CONTINUAR</template>
            <template v-else>
              <span
                class="button--loading "
                role="status"
                aria-hidden="true"
              ></span>
            </template>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import QuizCountDown from "@/components/QuizCountDown.vue";

import QuizStatements from "./../components/QuizStatements";
import QuestionTitle from "./../components/QuestionTitle";
import AnswerGroup from "./../components/AnswerGroup";
import DiscQuestionGroup from "./../components/DiscQuestionGroup";

import { mapGetters } from "vuex";

export default {
  components: {
    QuizCountDown,
    AnswerGroup,
    QuestionTitle,
    QuizStatements,
    DiscQuestionGroup,
  },
  data() {
    return {
      imgBody: require("@/assets/img/clear people sin letras.png"),
      imgLogo: require("@/assets/img/clear people naranja (1).png"),
      questionIndex: 0,
      chosenAnswers: [],
      disableButton: true,
      loadingButton: false,
      selectedAnswersValid: true,
    };
  },
  created() {
    if (this.quiz.maxDuration !== 0) {
      this.$store.dispatch(
        "QuizTimer/startQuizCountDown",
        this.quiz.maxDuration
      );
    }
    this.initProgressBar();
  },
  watch: {
    chosenAnswers: {
      immediate: true,
      handler() {
        if (this.chosenAnswers.length === 0) this.disableButton = true;
        else this.disableButton = false;
        if (this.quiz.name === "Test D.I.S.C") {
          if (this.chosenAnswers.length !== 2) this.disableButton = true;
          else this.disableButton = false;
        }
      },
    },
  },
  methods: {
    initProgressBar() {
      this.$Progress.start();
      this.$Progress.pause();
    },
    handleProgressBar() {
      const percentage = (100 * this.actualQuestion) / this.totalQuestions;
      this.$Progress.set(percentage);
    },
    submitQuestionHandler() {
      this.disableButton = true;
      if (this.isLastIndex) {
        this.saveChosenAnswers();
        this.submitQuizHandler();
      } else {
        this.saveChosenAnswers();
        this.questionIndex += 1;
      }
      this.handleProgressBar();
      this.disableButton = false;
    },
    async submitQuizHandler() {
      this.loadingButton = true;
      await this.$store.dispatch("Quiz/submitQuiz");
      this.loadingButton = false;
    },
    saveChosenAnswers() {
      if (this.quiz.name === "Test D.I.S.C") {
        for (const el of this.chosenAnswers) {
          this.$store.dispatch("Quiz/addChosenQuestionAnswers", {
            questionId: el.questionId,
            answers: el.answers,
          });
        }
      } else {
        this.$store.dispatch("Quiz/addChosenQuestionAnswers", {
          questionId: this.quiz.questions[this.questionIndex].id,
          answers: this.chosenAnswers,
        });
      }
    },
    handleValidForm(isValid) {
      this.selectedAnswersValid = isValid;
    },
  },
  computed: {
    ...mapGetters("Quiz", {
      quiz: "getQuiz",
    }),
    isDiscQuiz() {
      return this.quiz.name === "Test D.I.S.C";
    },
    withOutTimer() {
      return this.quiz.maxDuration !== 0;
    },
    discLastGroup() {
      return this.quiz.questions
        .map((el) => el.q_group)
        .reduce(
          (acc, value) => Math.max(acc, value),
          this.quiz.questions.map((el) => el.q_group)[0]
        );
    },
    isLastIndex() {
      if (this.quiz.name === "Test D.I.S.C")
        return this.questionIndex === this.discLastGroup - 1;
      return this.questionIndex === this.quiz.questions.length - 1;
    },
    actualQuestion() {
      return this.questionIndex + 1;
    },
    totalQuestions() {
      return this.quiz.questions.length;
    },
  },
};
</script>

<style>
.timer_container {
  margin-top: 30px;
}

.question {
  width: 80%;
  margin: 0 auto;
}
.question .answer {
  margin-top: 20px;
}
/* loading button */
.button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}
@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}
</style>

<style scoped>
.form {
  height: auto;
  padding-bottom: 30px;
  border-radius: 0px 0px 30px 30px;
}

.btn__comenzar {
  position: relative;
  margin-top: 20px;
  top: 0;
  max-width: 50%;
}

.submit_btn {
  display: flex;
  justify-content: center;
}
</style>
