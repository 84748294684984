<template>
  <div>
    <span class="currentQuestion" v-if="!isSingleTitle">
      {{ actualQuestion }}/{{ totalQuestions }}
    </span>
    <h3 class="row align-items-center">
      <template v-if="isSingleTitle">
        <span
          class="col-12 mb-3 mb-md-0"
          v-for="(statement, index) of title"
          :key="index"
        >
          <p class="text-center m-0 text-muted title_text">
            <span>{{ actualQuestion }})</span> {{ statement.statement }}
          </p>
        </span>
      </template>
      <template v-else>
        <span
          class="col-12 col-md-4 mb-3 mb-md-0"
          v-for="(statement, index) of title"
          :key="index"
        >
          <p class="text-center m-0 mb-2">{{ statement.description }}</p>
          <p class="text-center m-0 text-muted">
            {{ statement.statement }}
          </p>
        </span>
      </template>
    </h3>
  </div>
</template>

<script>
export default {
  name: "question-title",
  props: {
    title: {
      type: Array,
    },
    totalQuestions: {
      type: Number,
    },
    actualQuestion: {
      type: Number,
    },
  },
  computed: {
    isSingleTitle() {
      return this.title.length === 1;
    },
  },
};
</script>

<style scoped src="@/assets/css/boostrap-grid-system.css"></style>

<style scoped>
.currentQuestion {
  color: #5a5a5a;
}

h3 {
  font-weight: 200;
  color: #676767;
}

p {
  position: unset;
}

.title_text {
  text-align: left;
  padding-left: 20px;
}

.title_text span {
  font-weight: 400;
}
</style>
