<template>
  <div>
    <table class="mx-auto">
      <tr>
        <th class="px-2 px-md-4">Grupo {{ currentGroup }}</th>
        <th class="px-2 px-md-4">Menos</th>
        <th class="px-2 px-md-4">Más</th>
      </tr>
      <tr v-for="(question, index) of groups" :key="index">
        <td>{{ question.questionStatements[0].statement }}</td>
        <td>
          <div>
            <input
              type="radio"
              class="my-radio-btn"
              name="menos"
              :id="'menos-' + question.id"
              :value="menosAnswerId"
              @change="handleChange(question, menosAnswerId)"
            />
            <label :for="'menos-' + question.id"></label>
          </div>
        </td>
        <td>
          <div>
            <input
              type="radio"
              class="my-radio-btn"
              name="mas"
              :id="'mas-' + question.id"
              :value="masAnswerId"
              @change="handleChange(question, masAnswerId)"
            />
            <label :for="'mas-' + question.id"></label>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "disc-question-group",
  props: {
    questions: {
      type: Array,
    },
    value: {
      type: Array,
      default: () => [],
    },
    groupIndex: {
      type: Number,
      default: -1,
    },
    multipleChoice: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedAnswers: [],
    };
  },
  watch: {
    groupIndex: {
      immediate: true,
      handler() {
        this.selectedAnswers = [];
        this.$emit("input", this.selectedAnswers);
        const elements = document.getElementsByClassName("my-radio-btn");
        elements.forEach((el) => {
          el.checked = false;
        });
        this.$emit("valid", false);
      },
    },
  },
  methods: {
    handleChange(question, answerId) {
      const answerInList = this.selectedAnswers.some(
        (el) => el.answers[0] === answerId
      );

      if (answerInList) {
        const index = this.selectedAnswers.findIndex(
          (el) => el.answers[0] === answerId
        );
        this.selectedAnswers.splice(index, 1);
      }

      this.selectedAnswers.push({
        questionId: question.id,
        answers: [answerId],
      });

      this.$emit("valid", this.validSelected);
      this.$emit("input", this.selectedAnswers);
    },
  },
  computed: {
    validSelected() {
      let masCount = 0;
      let menosCount = 0;
      let answersIds = {};

      this.selectedAnswers.forEach((el) => {
        if (typeof answersIds[el.questionId] === "undefined")
          answersIds[el.questionId] = 1;
        else answersIds[el.questionId] += 1;

        if (el.answers[0] === this.masAnswerId) {
          masCount += 1;
        }
        if (el.answers[0] === this.menosAnswerId) {
          menosCount += 1;
        }
      });

      for (const key in answersIds) {
        if (answersIds[key] > 1) return false;
      }

      if (masCount > 1 || menosCount > 1) return false;
      if (masCount === 0 || menosCount === 0) return false;
      return true;
    },
    currentGroup() {
      return this.groupIndex + 1;
    },
    groups() {
      const showGroup = this.groupIndex + 1;
      return this.questions.filter((el) => el.q_group == showGroup);
    },
    answers() {
      return this.questions[0].answers;
    },
    masAnswerId() {
      let id = -1;
      for (const el of this.answers) {
        if (el.reply == "Más") id = el.id;
      }
      return id;
    },
    menosAnswerId() {
      let id = -1;
      for (const el of this.answers) {
        if (el.reply == "Menos") id = el.id;
      }
      return id;
    },
  },
};
</script>

<style scoped src="bootstrap/dist/css/bootstrap.min.css"></style>

<style>
table,
th,
td {
  border: none;
  text-align: center;
}

th {
  color: #5a5a5a;
}

td {
  padding: 5px;
  color: #676767;
}
</style>
