<template>
  <div>
    <template v-if="multipleChoice">
      <div
        class="answer"
        v-for="(answer, index) of questionAnswers"
        :key="index"
      >
        <input
          type="checkbox"
          :id="index"
          :value="answer.id"
          v-model="selectedAnswers"
          @change="handleChange"
        />
        <label :for="index">{{ answer.reply | answerText }}</label>
      </div>
    </template>

    <template v-else>
      <div
        class="answer"
        v-for="(answer, index) of questionAnswers"
        :key="index"
      >
        <input
          type="radio"
          name="res"
          :id="index"
          :value="answer.id"
          @change="handleChange"
          v-model="radioSelectedAnswer"
        />
        <label :for="index">{{ answer.reply | answerText }}</label>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "question-answer-group",
  props: {
    questionAnswers: {
      type: Array,
    },
    value: {
      type: Array,
      default: () => [],
    },
    questionIndex: {
      type: Number,
      default: -1,
    },
    multipleChoice: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedAnswers: this.value,
      radioSelectedAnswer: undefined,
    };
  },
  methods: {
    handleChange() {
      if (this.multipleChoice) {
        this.$emit("input", this.selectedAnswers);
      } else {
        if (this.radioSelectedAnswer === undefined) {
          this.$emit("input", []);
        } else {
          const selectedAnswer = [];
          selectedAnswer.push(this.radioSelectedAnswer);
          this.$emit("input", selectedAnswer);
        }
      }
    },
  },
  watch: {
    questionIndex: {
      immediate: true,
      handler() {
        this.selectedAnswers = [];
        this.radioSelectedAnswer = undefined;
        this.handleChange();
      },
    },
  },
  filters: {
    answerText(val) {
      if (val.toLowerCase() === "skip") return "Saltar";
      return val;
    },
  },
};
</script>

<style>
/* inicio - css radio checkbox */
/* Basic styles */
input[type="checkbox"],
input[type="radio"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
label {
  position: relative;
  display: inline-block;
  padding: 3px 0 0 2.2em;
  line-height: 1.5;
  cursor: pointer;
  font-size: 13px;
  color: #999999;
}
label::before,
label::after {
  position: absolute;
  top: 0.5px;
  left: 0.5px;
  display: block;
  width: 1.5em;
  height: 1.5em;
}
label::before {
  content: " ";
  border: 2px solid #bdc3c7;
  border-radius: 20%;
}
/* Checkbox */
input[type="checkbox"] + label::after {
  content: "\2714";
  color: #fff;
  line-height: 1.6;
  padding-left: 2px;
  padding-top: 2px;
  text-align: center;
}
/* Radio */
input[type="radio"] + label::before {
  border-radius: 50%;
}
input[type="radio"] + label::after {
  content: " ";
  top: 0.29em;
  left: 0.305em;
  width: 1em;
  height: 1em;
  background: #d95f1f;
  border: 0.2em solid #fff;
  border-radius: 50%;
}
/* :checked */
input[type="checkbox"]:checked + label::before,
input[type="radio"]:checked + label::before {
  background: #d95f1f;
  border-color: #d95f1f;
}
input[type="checkbox"] + label::after,
input[type="radio"] + label::after {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
input[type="checkbox"]:checked + label::after,
input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
/* Transition */
label::before,
label::after {
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}
/* fin - css radio checkbox */
</style>
