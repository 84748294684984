<template>
  <div>
    <h1 class="timer">{{ time }}</h1>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "quiz-count-down",
  created() {
    // this.startTimer();
  },
  computed: {
    ...mapGetters("QuizTimer", { time: "getTime" }),
  },
  methods: {
    // startTimer() {
    //   this.$store.dispatch("QuizTimer/startQuizCountDown", 5);
    // },
  },
};
</script>

<style>
.timer {
  text-align: center;
  color: #dc9640;
  font-weight: 200;
}
</style>
