<template>
  <ol>
    <li v-for="(item, index) in statements" :key="index">
      <p>{{ item.statement }}</p>
    </li>
  </ol>
</template>

<script>
export default {
  name: "quiz-statements",
  props: {
    statements: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
ol {
  margin: 20px 0;
}

ol li {
  margin-bottom: 20px;
  color: #676767;
}

p {
  position: unset;
  text-align: justify;
}
</style>
